body {
  box-sizing: border-box;
}

:root {
  --game-viewport-width: 180px;
  --game-viewport-height: 180px;
  --game-cell-size: 16px;
  --pixel-size: 1;
  --ui-popup-z-index: 500;
}

html,
body {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  margin: 0;
}

canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-crisp-edges;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

/* Scale up game art if we can fit it. 2x */

@media (min-width: 360px) and  (min-height: 360px)  {
  :root {
    --pixel-size: 2;
  }
}

/* 3x */
@media(min-width: 540px) and (min-height: 540px) {
  :root {
      --pixel-size: 3;
  }
}

/*4x*/
@media(min-width: 720px) and (min-height: 720px) {
  :root {
      --pixel-size: 4;
  }
}
/* 5x */
@media(min-width: 900px) and (min-height: 900px) {
  :root {
      --pixel-size: 5;
  }
}

/* 6x */

@media (min-width: 1080px) and (min-height: 1080px) {
  :root {
    --pixel-size: 6;
  }
}

/* 7x */

@media (min-width: 1260px) and (min-height: 1260px) {
  :root {
    --pixel-size: 7;
  }
}
