.fullScreenContainer {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
}

.gameScreen {
  width: var(--game-viewport-width);
  height: var(--game-viewport-height);
  transform: scale(var(--pixel-size));
 /* outline: 2px solid red; */
}
